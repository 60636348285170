<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.monitoring_schedule') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.fiscal_year')"
                    label-for="fiscal_year_id"
                >
                    <b-form-select
                    plain
                    id="fiscal_year_id"
                    :options="fiscalYearList"
                    v-model="search.fiscal_year_id"
                    placeholder=""
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
             <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.organization')"
                    label-for="org_id"
                >
                    <b-form-select
                    plain
                    id="org_id"
                    :options="orgList"
                    v-model="search.org_id"
                    placeholder=""
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('research_manage.team_name')"
                    label-for="team_id"
                >
                    <b-form-select
                        plain
                        id="team_id"
                        :options="monitoringTeamList"
                        v-model="search.team_id"
                        placeholder=""
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.monitoring_team') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(schedule)="data">
                        <router-link :to="`monitoring-schedule-projects?id=${data.item.id}`">
                          <b-button variant=" iq-bg-success mr-1 border" :title="`${$t('research_manage.monitoring_schedule')} ${$t('globalTrans.entry')}`" size="sm"><i class='fas fa-redo-alt' style='font-size:15px'></i></b-button>
                        </router-link>
                      <b-button class="mr-1" title="View Details" v-b-modal.modal-detail variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)"><i class="fa fa-eye m-0 "></i></b-button>
                    </template>
                    <template v-slot:cell(status)="data">
                        <span v-if="data.item">{{ 'No Schedule Available' }}</span>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
     <b-modal id="modal-detail" size="xl" :title="`${$t('research_manage.team_member')} ${$t('globalTrans.details')}`" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailModal :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import DetailModal from './DetailModal.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { monitoringScheduleList } from '../../../api/routes'
import RouteBasedList from '@/mixins/route-base-list'

export default {
  mixins: [RouteBasedList],
  components: {
    FormV,
    DetailModal
  },
  data () {
    return {
      editItemId: 0,
      search: {
        team_id: 0,
        org_id: 0,
        fiscal_year_id: 0
      }
    }
  },
  computed: {
    orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    monitoringTeamList: function () {
        return this.$store.state.AgriResearch.commonObj.monitoringTeamList.filter(item => item.status === 1)
    },
    formTitle () {
        return (this.editItemId === 0) ? this.$t('research_manage.monitoring_team') + ' ' + this.$t('globalTrans.entry') : this.$t('research_manage.monitoring_team') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.fiscal_year'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-left' },
          { label: this.$t('research_manage.team_name'), class: 'text-left' },
          { label: this.$t('research_manage.monitoring_schedule'), class: 'text-center' }
          // { label: this.$t('globalTrans.status'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fis_name_bn' },
          { key: 'org_name_bn' },
          { key: 'team_name_bn' },
          { key: 'schedule' }
          // { key: 'status' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fis_name' },
          { key: 'org_name' },
          { key: 'team_name' },
          { key: 'schedule' }
          // { key: 'status' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
  //
  },
  mounted () {
    core.index()
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, monitoringScheduleList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
          const tmpFiscal = this.$store.state.commonObj.fiscalYearList.find(type => type.value === item.fiscal_year_id)
          const tmpTeam = this.$store.state.AgriResearch.commonObj.monitoringTeamList.find(type => type.value === item.team_id)
          const tmpOrg = this.$store.state.commonObj.organizationProfileList.find(type => type.value === item.org_id)
          const newData = {
              fis_name: tmpFiscal !== undefined ? tmpFiscal.text_en : '',
              fis_name_bn: tmpFiscal !== undefined ? tmpFiscal.text_bn : '',
              team_name: tmpTeam !== undefined ? tmpTeam.text_en : '',
              team_name_bn: tmpTeam !== undefined ? tmpTeam.text_bn : '',
              org_name: tmpOrg !== undefined ? tmpOrg.text_en : '',
              org_name_bn: tmpOrg !== undefined ? tmpOrg.text_bn : ''
          }
          return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>
